/**********************
Config
**********************/
/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Waterfall&display=swap");
/* Every section's content */
.navbar-content, .about-content, .skills-content, .projects-content, .education-content, .contact-content {
  width: 92%;
  max-width: 35rem;
  margin: 0 auto;
}

/* Projects & Education Header Title (Icon + h2) */
.projects-content header, .education-content header {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 1rem auto;
}

.projects-content header span, .education-content header span {
  background-color: #fff;
  border-radius: 50%;
  color: #11cdef;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  margin-right: 1.3rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.projects-content header h2, .education-content header h2 {
  font-size: 2rem;
}

/* About-page Resume Btn */
.about-content article .socials #hero-resume-btn {
  display: flex;
  background-color: #fff;
  color: #000;
  padding: 0.75rem 0.2rem;
  font-weight: 600;
  border-radius: 3px;
  align-items: center;
  justify-content: space-evenly;
  width: 11.5rem;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3);
}

.about-content article .socials #hero-resume-btn svg {
  font-size: 1.2rem;
}

.about-content article .socials #hero-resume-btn span {
  text-transform: uppercase;
  font-size: 0.9rem;
}

/* About-page & Footer socials */
.about-content article .socials #colored-socials, .contact .socials #colored-socials {
  display: flex;
  width: 12rem;
  color: white;
  justify-content: space-around;
}

.about-content article .socials #colored-socials a, .contact .socials #colored-socials a {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-right: 0.75rem;
  margin-bottom: 1.2rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  /* Footer ONLY */
}

.about-content article .socials #colored-socials a svg, .contact .socials #colored-socials a svg {
  font-size: 1.2rem;
}

.about-content article .socials #colored-socials a:first-child, .contact .socials #colored-socials a:first-child {
  background-color: #222;
}

.about-content article .socials #colored-socials a:nth-child(2), .contact .socials #colored-socials a:nth-child(2) {
  background-color: #1da1f2;
}

.about-content article .socials #colored-socials a:nth-child(3), .contact .socials #colored-socials a:nth-child(3) {
  background-color: #e4405f;
}

.about-content article .socials #colored-socials a:nth-child(4), .contact .socials #colored-socials a:nth-child(4) {
  background-color: #a84ea8;
}

/* 992px + | navbar socials  */
.navbar .socials {
  width: 20%;
  max-width: 12rem;
  z-index: 5;
}

.navbar .socials div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar .socials div svg {
  cursor: pointer;
  font-size: 1.2rem;
}

/* Project-card btns */
.project-card #btn-container a {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Project-card && Education-card */
.project-card, .education-card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.15s ease;
}

/* Project-Card */
.project-card:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.6);
}

@media (min-width: 768px) {
  .navbar-content, .about-content, .skills-content, .projects-content, .education-content, .contact-content {
    max-width: 50rem;
  }
  /* About-page content slightly smaller from 768px to 992px */
  .about .about-content article {
    max-width: 45rem;
  }
}

@media (min-width: 992px) {
  .navbar-content, .about-content, .skills-content, .projects-content, .education-content, .contact-content {
    max-width: 55rem;
  }
}

@media (min-width: 1200px) {
  .navbar-content, .about-content, .skills-content, .projects-content, .education-content, .contact-content {
    max-width: 60rem;
  }
}

/**********************
Main CSS (General tags)
**********************/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-family: sans-serif;
  overflow-x: hidden;
}

.pages {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:visited {
  color: inherit;
}

li {
  list-style-type: none;
}

/***************************************
Navbar, nav-dropdown, nav-buttons, scrollToTop 
****************************************/
.nav-container {
  width: 100%;
}

.navbar {
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  transition: all 0.3s linear;
  height: 5rem;
  color: #fff;
}

.navbar-fixed {
  position: fixed;
  width: 100%;
  background-color: #172b4d;
  z-index: 7;
}

.navbar-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-content h3 {
  font-family: "Waterfall", cursive;
  font-weight: 100;
  font-size: 2.5rem;
  width: 90%;
}

.navbar .socials {
  display: none;
}

#open-menu-btn {
  width: 10%;
  z-index: 5;
  color: white;
  background-color: transparent;
  border: none;
  padding-top: 0.5rem;
  cursor: pointer;
}

#open-menu-btn svg {
  font-size: 1.8rem;
}

/* Dropdown menu < 992px */
.navbar-dropdown {
  width: 93vw;
  margin: 0.7rem;
  height: auto;
  position: absolute;
  top: 0rem;
  right: 0;
  left: 0;
  opacity: 0;
  overflow-y: auto;
}

.navbar-dropdown div:first-of-type {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Separation line */
}

.navbar-dropdown div:first-of-type h3 {
  font-family: "Waterfall", cursive;
  font-weight: 600;
  font-size: 2.5rem;
  color: #172b4d;
  width: 80%;
}

.navbar-dropdown div:first-of-type #close-menu-btn {
  width: 5%;
  background-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  font-size: 1.2rem;
  color: #172b4d;
  cursor: pointer;
}

.navbar-dropdown div:first-of-type::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1rem;
  left: 0;
  background-color: #ddd;
}

.navbar-dropdown div:last-child ul {
  margin-top: 1.5rem;
}

.navbar-dropdown div:last-child ul li {
  padding: 0.5rem;
}

.navbar-dropdown div:last-child ul li a {
  display: flex;
  align-items: center;
  color: #172b4d;
}

.navbar-dropdown div:last-child ul li a svg {
  margin-right: 0.5rem;
  padding-bottom: 2px;
  font-size: 1.2rem;
}

.navbar-dropdown-open {
  position: fixed;
  padding: 1.5rem;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  animation: show-navbar-collapse 0.2s ease forwards;
  z-index: 9;
}

@media (min-width: 992px) {
  /* Navbar responsive */
  #open-menu-btn {
    display: none;
  }
  .navbar-dropdown-open {
    display: none;
  }
  .navbar .socials {
    display: flex;
  }
}

/* Navbar dropdown panel animation */
@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

/* Scroll to top button */
#scrollTop {
  background-color: red;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
}

/***************************************
Hero Page
****************************************/
/* About Page (Hero) */
.about {
  position: relative;
  width: 100%;
  padding: 6rem 0;
}

.about-content {
  /* About Page Text */
}

.about-content article {
  color: #fff;
}

.about-content article h1 {
  font-size: 2rem;
}

.about-content article p {
  line-height: 1.5;
  font-size: 1.25rem;
  padding: 0.7rem 0 1rem 0;
  width: 100%;
}

.about-content article p:last-of-type {
  padding-bottom: 3rem;
}

.about-content figure {
  width: 75%;
  max-width: 40rem;
  margin: 8rem auto;
  position: relative;
}

.about-content figure::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 28%;
  right: 25px;
  left: 0;
  margin: auto;
  width: 25%;
  height: 38%;
  background-color: #ddd;
  z-index: -1;
}

.about-content figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* background gradient + "bubbles" for about-section */
#hero-bg {
  position: absolute;
  z-index: -1;
  top: -25%;
  left: -10%;
  width: 150%;
  height: 110%;
  background: linear-gradient(35deg, #11cdef, #1171ef);
  transform: rotateZ(-3deg);
}

#hero-bg span {
  position: absolute;
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

#hero-bg span:nth-child(1) {
  background-color: rgba(255, 255, 255, 0.1);
  left: 20%;
  bottom: auto;
}

#hero-bg span:nth-child(2) {
  background-color: rgba(255, 255, 255, 0.1);
  right: 14%;
  top: 20%;
}

#hero-bg span:nth-child(3) {
  background-color: rgba(255, 255, 255, 0.3);
  top: 380px;
  right: 23%;
}

#hero-bg span:nth-child(4) {
  background-color: rgba(255, 255, 255, 0.15);
  top: 430px;
  right: 9%;
}

#hero-bg span:nth-child(5) {
  background-color: rgba(255, 255, 255, 0.05);
  top: 38%;
  left: 10%;
  right: auto;
}

#hero-bg span:nth-child(6) {
  background-color: rgba(255, 255, 255, 0.15);
  width: 200px;
  height: 200px;
  top: 54%;
  left: 10%;
  right: auto;
}

#hero-bg span:nth-child(7) {
  background-color: rgba(255, 255, 255, 0.05);
  bottom: 60%;
  right: 36%;
}

#hero-bg span:nth-child(8) {
  background-color: rgba(255, 255, 255, 0.2);
  bottom: 70px;
  right: 30%;
}

#hero-bg span:nth-child(9) {
  background-color: rgba(255, 255, 255, 0.1);
  bottom: 1%;
  right: 30%;
}

@media (min-width: 992px) {
  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12rem;
  }
  .about-content article {
    width: 50%;
  }
  .about-content figure {
    width: 45%;
    margin: auto 0;
  }
}

/***************************************
Skills-Section
****************************************/
.skills {
  margin: 10rem auto 0 auto;
}

.skills-content {
  margin: 5rem auto 0 auto;
}

.skills-content header {
  text-align: center;
  color: #32325d;
  font-size: 1.2rem;
}

.skills-content header h2 {
  font-size: 2.5rem;
  font-weight: 100;
  margin-bottom: 1rem;
}

.skills-content div:first-of-type {
  margin: 3rem auto;
  display: flex;
  flex-flow: row wrap;
}

.skills-content figure {
  width: 70%;
  max-width: 30rem;
  margin: auto;
}

.skills-content figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.skills-content .lists-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills-content ul:first-of-type {
  display: flex;
  flex-flow: row wrap;
}

.skills-content ul:first-of-type li {
  border-radius: 50%;
  width: 13%;
  max-width: 3.5rem;
  max-height: 3.5rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: 0 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skills-content ul:first-of-type li img {
  width: 75%;
  height: 75%;
  object-fit: contain;
}

.skills-content ul:last-child {
  margin: 3.5rem auto;
  color: #525f7f;
}

.skills-content ul:last-child li {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.skills-content ul:last-child li span {
  margin-right: 1rem;
  font-size: 1.4rem;
}

@media (min-width: 992px) {
  .skills-content div:first-of-type {
    flex-direction: row;
    align-items: center;
  }
  .skills-content div:first-of-type figure {
    width: 50%;
  }
  .skills-content div:first-of-type .lists-container {
    padding: 0;
    height: 100%;
    width: 50%;
  }
  .skills-content div:first-of-type .lists-container ul:first-of-type {
    margin-bottom: 3rem;
  }
  .skills-content div:first-of-type .lists-container ul:first-of-type li {
    width: 4rem;
    height: 4rem;
  }
}

/***************************************
Projects-section
****************************************/
.projects {
  position: relative;
  padding: 8rem 0 0 0;
}

.projects-content header {
  color: #fff;
}

.projects-content .projects-container {
  display: flex;
  flex-direction: column;
}

.project-card {
  border-radius: 10px;
  max-width: 21.5rem;
  max-height: 50rem;
  margin: 2rem auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.project-card-info {
  color: #32325d;
  padding: 0.7rem;
}

.project-card-info h3 {
  margin-top: 0.2rem;
  letter-spacing: 1px;
  font-size: 1.4rem;
}

.project-card-info h5 {
  font-size: 0.8rem;
  opacity: 0.5;
  margin: 0.2rem 0 0.7rem 0;
  position: relative;
}

.project-card-info h5::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  margin: auto;
}

.project-card-info p {
  color: #525f7f;
  font-size: 0.9rem;
  line-height: 1.3;
  padding: 0.5rem 0;
}

.project-card #btn-container {
  padding: 0.4rem;
  display: flex;
  column-gap: 0.5rem;
}

.project-card #btn-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.4em 0.7em;
  border-radius: 5px;
  transition: all 0.2s ease;
  will-change: transform;
}

.project-card #btn-container a:hover {
  transform: translateY(-1px);
}

.project-card #btn-container a svg {
  margin-right: 0.2rem;
  position: relative;
  top: 1px;
}

.project-card #btn-container a:first-of-type {
  background-color: #2dce89;
}

.project-card #btn-container a:first-of-type:hover {
  background-color: #26af74;
}

.project-card #btn-container a:last-child {
  background-color: #1e1e1e;
}

.project-card #btn-container a:last-child:hover {
  background-color: #1a1a1a;
}

.slider-container {
  position: relative;
}

.slider-container figure {
  height: 18rem;
}

.slider-container figure img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 20%;
}

.slider-container .otherImage,
.slider-container .nextImage {
  display: none;
}

.slider-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}

.slider-btns button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.slider-btns button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.slider-btns button:first-of-type {
  border-radius: 0 7px 0 0;
}

.slider-btns button:last-child {
  border-radius: 7px 0 0 0;
}

.slider-btns button svg {
  font-size: 1rem;
}

/* Projects-section background gradient */
#projects-bg {
  position: absolute;
  top: 0;
  left: -25%;
  width: 150%;
  height: 100%;
  background: linear-gradient(35deg, #11cdef, #1171ef);
  transform: rotateZ(-3deg);
  z-index: -1;
}

#projects-bg::before {
  content: "";
  width: 120%;
  height: 10rem;
  background-color: #fff;
  position: absolute;
  top: -4%;
  transform: rotateZ(3deg);
  z-index: 0;
}

@media (min-width: 768px) {
  .project-card {
    max-width: 50rem;
    flex-direction: row;
    justify-content: space-between;
  }
  .project-card .project-content {
    width: 58%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .project-card .project-content #btn-container {
    justify-content: flex-end;
  }
  .project-card-info {
    padding: 1rem 0.5rem;
  }
  .project-card-info h5 {
    margin: 0.5rem 0 1rem 0;
  }
  .project-card-info p {
    padding: 0.8rem 0;
    line-height: 1.7;
  }
  .slider-container {
    width: 40%;
  }
  .slider-container figure img {
    border-radius: 10px 0 0 10px;
  }
  .slider-btns button:first-of-type {
    border-radius: 0 5px 0 10px;
  }
  .slider-btns button:last-child {
    border-radius: 5px 0 0 0;
  }
}

/***************************************
Education-section
****************************************/
.education {
  margin: 10rem auto;
}

.education-content header {
  color: #11cdef;
}

.education-content .education-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

/* Each card */
.education-card {
  margin: 2rem 1rem;
  max-width: 30rem;
  padding: 1.5rem 1.5rem;
  color: #525f7f;
  transition: transform 0.05s ease;
}

.education-card:hover {
  transform: translateY(-20px);
}

.education-card h3 {
  color: #11cdef;
  font-weight: 600;
}

.education-card h5 {
  margin: 0.7rem 0;
  font-size: 1rem;
  font-weight: 400;
  color: #32325d;
}

.education-card span {
  background-color: #11cdef;
  color: #fff;
  border-radius: 3px;
  padding: 0.2rem 0.3rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
}

.education-card p {
  padding: 1rem 0;
  font-size: 0.9rem;
}

.education-card ul {
  margin-left: 2rem;
}

.education-card ul li {
  list-style-type: disc;
  margin: 0.5rem 0;
}

@media (min-width: 768px) {
  .education-card {
    max-width: none;
    width: 45rem;
    padding: 2rem 3rem;
  }
}

@media (min-width: 992px) {
  .education-card {
    width: 25rem;
    margin: 2rem 1rem;
  }
}

/***************************************
Contact-footer-section
****************************************/
.contact {
  background: linear-gradient(35deg, #11cdef, #1171ef);
}

.contact-content {
  padding: 5rem 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-content figure {
  width: 159px;
  height: 212.375px;
  box-shadow: 3px 3px 15px  #1e1e1e;
  margin-bottom: 1rem;
}

.contact-content figure img {
  width: 100%;
  object-fit: cover;
}

.contact-content .contact-info {
  width: 100%;
}

.contact-content .contact-info h3 {
  font-size: 1.9rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.contact-content .contact-info h5 {
  font-size: 1.1rem;
  padding: 1rem 0;
  font-weight: 600;
  letter-spacing: 0.8px;
  max-width: 40rem;
}

.contact-content .contact-info span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3px;
  color: #11cdef;
  width: 10.5rem;
  padding: 0.8rem 0.1rem;
  margin: 2rem 0 1rem 0;
  font-weight: 600;
}

.contact-content .contact-info span svg {
  font-size: 1.5rem;
}

.contact .socials #colored-socials {
  width: 13rem;
}

@media (min-width: 992px) {
  .contact-content {
    flex-direction: row;
    justify-content: space-around;
  }
  .contact-content figure {
    order: 1;
  }
  .contact-content .contact-info {
    width: 60%;
  }
}
